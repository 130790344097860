import { Areas } from "../components/areas/areas"
import { ContainerWithPadding } from "../components/shared"
import Layout from "../components/Layout"
import { Principles } from "../components/principles/principles"
import React, { FC } from "react"
import { ShortDescription } from "../components/ShortDescription/ShortDescription"
import { TextBlock } from "../components/info-title/text-block"
import { TopBlock } from "../components/TopBlock/TopBlock"
import { TopBlockCounters } from "../components/TopBlock/TopBlockCounters"
import { graphql } from "gatsby"
import { PageWithQuery } from "../types/PageWithQuery"
import { AboutSection } from "../components/AboutSection"

const IndexPage: FC<
  PageWithQuery<
    {},
    {
      content: string
    }
  >
> = ({
  data: {
    pageData: { content },
    translationData: {
      frontmatter: {
        topBlockStatistic,
        address,
        navigationItems,
        contactUsTitle,
      },
    },
    commonData: {
      frontmatter: { phoneNumber, email, addressCoordinates },
    },
  },
}) => {
  return (
    <Layout
      lang="ua"
      phoneNumber={phoneNumber}
      email={email}
      address={address}
      navigationItems={navigationItems}
      addressCoordinates={addressCoordinates}
      contactUsTitle={contactUsTitle}
    >
      <ContainerWithPadding padding={`32px 32px 0`}>
        <TopBlock>
          <div className="bottom">
            <TopBlockCounters counters={Object.values(topBlockStatistic)} />
          </div>
        </TopBlock>
      </ContainerWithPadding>
      <ContainerWithPadding>
        <AboutSection
          dangerouslySetInnerHTML={{ __html: content }}
        ></AboutSection>
      </ContainerWithPadding>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  {
    pageData: markdownRemark(
      frontmatter: { lang: { eq: "ua" }, pageType: { eq: "about" } }
    ) {
      content: html
    }
    translationData: markdownRemark(
      frontmatter: { lang: { eq: "ua" }, pageType: { eq: "translations" } }
    ) {
      frontmatter {
        navigationItems {
          about
          blog
          contacts
          team
        }
        contactUsTitle
        address
        topBlockStatistic {
          ares {
            number
            title
          }
          wonCases {
            number
            title
          }
          years {
            number
            title
          }
        }
      }
    }
    commonData: markdownRemark(frontmatter: { pageType: { eq: "common" } }) {
      frontmatter {
        phoneNumber
        email
        addressCoordinates {
          lat
          lng
        }
      }
    }
  }
`
